define("@ember-data/model/has-many-774c4c4b", ["exports", "@ember/debug", "@ember/object", "@ember-data/store", "@ember-data/store/-private", "@ember-data/model/model-b638e17c", "@ember/array", "@ember/string", "ember-inflector", "@embroider/macros/runtime"], function (_exports, _debug, _object, _store, _private, _modelB638e17c, _array, _string, _emberInflector, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.h = _exports.b = _exports.a = void 0;
  /**
    @module @ember-data/model
  */

  /**
    `attr` defines an attribute on a [Model](/ember-data/release/classes/Model).
    By default, attributes are passed through as-is, however you can specify an
    optional type to have the value automatically transformed.
    Ember Data ships with four basic transform types: `string`, `number`,
    `boolean` and `date`. You can define your own transforms by subclassing
    [Transform](/ember-data/release/classes/Transform).
  
    Note that you cannot use `attr` to define an attribute of `id`.
  
    `attr` takes an optional hash as a second parameter, currently
    supported options are:
  
    - `defaultValue`: Pass a string or a function to be called to set the attribute
    to a default value if and only if the key is absent from the payload response.
  
    Example
  
    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';
  
    export default class UserModel extends Model {
      @attr('string') username;
      @attr('string') email;
      @attr('boolean', { defaultValue: false }) verified;
    }
    ```
  
    Default value can also be a function. This is useful it you want to return
    a new object for each attribute.
  
    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';
  
    export default class UserModel extends Model {
      @attr('string') username;
      @attr('string') email;
  
      @attr({
        defaultValue() {
          return {};
        }
      })
      settings;
    }
    ```
  
    The `options` hash is passed as second argument to a transforms'
    `serialize` and `deserialize` method. This allows to configure a
    transformation and adapt the corresponding value, based on the config:
  
    ```app/models/post.js
    import Model, { attr } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @attr('text', {
        uppercase: true
      })
      text;
    }
    ```
  
    ```app/transforms/text.js
    export default class TextTransform {
      serialize(value, options) {
        if (options.uppercase) {
          return value.toUpperCase();
        }
  
        return value;
      }
  
      deserialize(value) {
        return value;
      }
  
      static create() {
        return new this();
      }
    }
    ```
  
    @method attr
    @public
    @static
    @for @ember-data/model
    @param {String|Object} type the attribute type
    @param {Object} options a hash of options
    @return {Attribute}
  */function attr(type, options) {
    if (typeof type === 'object') {
      options = type;
      type = undefined;
    } else {
      options = options || {};
    }
    let meta = {
      type: type,
      isAttribute: true,
      options: options
    };
    return (0, _object.computed)({
      get(key) {
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/drive.inc24.net/applications/cms/node_modules/@ember-data/model").env.DEBUG)) {
          if (['currentState'].indexOf(key) !== -1) {
            throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ${this.constructor.toString()}`);
          }
        }
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        return (0, _private.peekCache)(this).getAttr((0, _store.recordIdentifierFor)(this), key);
      },
      set(key, value) {
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/drive.inc24.net/applications/cms/node_modules/@ember-data/model").env.DEBUG)) {
          if (['currentState'].indexOf(key) !== -1) {
            throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ${this.constructor.toString()}`);
          }
        }
        (true && !(!this.currentState.isDeleted) && (0, _debug.assert)(`Attempted to set '${key}' on the deleted record ${(0, _store.recordIdentifierFor)(this)}`, !this.currentState.isDeleted));
        const identifier = (0, _store.recordIdentifierFor)(this);
        const cache = (0, _private.peekCache)(this);
        let currentValue = cache.getAttr(identifier, key);
        if (currentValue !== value) {
          cache.setAttr(identifier, key, value);
          if (!this.isValid) {
            const {
              errors
            } = this;
            if (errors.get(key)) {
              errors.remove(key);
              this.currentState.cleanErrorRequests();
            }
          }
        }
        return value;
      }
    }).meta(meta);
  }
  var attr$1 = _exports.a = (0, _modelB638e17c.c)(attr);

  /**
    @module @ember-data/model
  */

  /**
    `belongsTo` is used to define One-To-One and One-To-Many
    relationships on a [Model](/ember-data/release/classes/Model).
  
  
    `belongsTo` takes an optional hash as a second parameter, currently
    supported options are:
  
    - `async`: A boolean value used to explicitly declare this to be an async relationship. The default is true.
    - `inverse`: A string used to identify the inverse property on a
      related model in a One-To-Many relationship. See [Explicit Inverses](#explicit-inverses)
    - `polymorphic` A boolean value to mark the relationship as polymorphic
  
    #### One-To-One
    To declare a one-to-one relationship between two models, use
    `belongsTo`:
  
    ```app/models/user.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class UserModel extends Model {
      @belongsTo('profile') profile;
    }
    ```
  
    ```app/models/profile.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class ProfileModel extends Model {
      @belongsTo('user') user;
    }
    ```
  
    #### One-To-Many
  
    To declare a one-to-many relationship between two models, use
    `belongsTo` in combination with `hasMany`, like this:
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment', { async: false, inverse: 'post' }) comments;
    }
    ```
  
    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class CommentModel extends Model {
      @belongsTo('post', { async: false, inverse: 'comments' }) post;
    }
    ```
  
    #### Sync relationships
  
    Ember Data resolves sync relationships with the related resources
    available in its local store, hence it is expected these resources
    to be loaded before or along-side the primary resource.
  
    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class CommentModel extends Model {
      @belongsTo('post', {
        async: false,
        inverse: null
      })
      post;
    }
    ```
  
    In contrast to async relationship, accessing a sync relationship
    will always return the record (Model instance) for the existing
    local resource, or null. But it will error on access when
    a related resource is known to exist and it has not been loaded.
  
    ```
    let post = comment.post;
  
    ```
  
    @method belongsTo
    @public
    @static
    @for @ember-data/model
    @param {String} modelName (optional) type of the relationship
    @param {Object} options (optional) a hash of options
    @return {Ember.computed} relationship
  */
  function belongsTo(modelName, options) {
    let opts = options;
    let userEnteredModelName = modelName;
    (true && !(opts && typeof opts.async === 'boolean') && (0, _debug.assert)(`Expected options.async from @belongsTo('${userEnteredModelName}', options) to be a boolean`, opts && typeof opts.async === 'boolean'));
    (true && !(opts.inverse === null || typeof opts.inverse === 'string' && opts.inverse.length > 0) && (0, _debug.assert)(`Expected options.inverse from @belongsTo('${userEnteredModelName}', options) to be either null or the string type of the related resource.`, opts.inverse === null || typeof opts.inverse === 'string' && opts.inverse.length > 0));
    let meta = {
      type: (0, _modelB638e17c.n)(userEnteredModelName),
      isRelationship: true,
      options: opts,
      kind: 'belongsTo',
      name: 'Belongs To',
      key: null
    };
    return (0, _object.computed)({
      get(key) {
        // this is a legacy behavior we may not carry into a new model setup
        // it's better to error on disconnected records so users find errors
        // in their logic.
        if (this.isDestroying || this.isDestroyed) {
          return null;
        }
        const support = (0, _modelB638e17c.l)(this);
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/drive.inc24.net/applications/cms/node_modules/@ember-data/model").env.DEBUG)) {
          if (['currentState'].indexOf(key) !== -1) {
            throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your belongsTo on ${this.constructor.toString()}`);
          }
          if (Object.prototype.hasOwnProperty.call(opts, 'serialize')) {
            (true && (0, _debug.warn)(`You provided a serialize option on the "${key}" property in the "${support.identifier.type}" class, this belongs in the serializer. See Serializer and it's implementations https://api.emberjs.com/ember-data/release/classes/Serializer`, false, {
              id: 'ds.model.serialize-option-in-belongs-to'
            }));
          }
          if (Object.prototype.hasOwnProperty.call(opts, 'embedded')) {
            (true && (0, _debug.warn)(`You provided an embedded option on the "${key}" property in the "${support.identifier.type}" class, this belongs in the serializer. See EmbeddedRecordsMixin https://api.emberjs.com/ember-data/release/classes/EmbeddedRecordsMixin`, false, {
              id: 'ds.model.embedded-option-in-belongs-to'
            }));
          }
        }
        return support.getBelongsTo(key);
      },
      set(key, value) {
        const support = (0, _modelB638e17c.l)(this);
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/drive.inc24.net/applications/cms/node_modules/@ember-data/model").env.DEBUG)) {
          if (['currentState'].indexOf(key) !== -1) {
            throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your belongsTo on ${this.constructor.toString()}`);
          }
        }
        this.store._join(() => {
          support.setDirtyBelongsTo(key, value);
        });
        return support.getBelongsTo(key);
      }
    }).meta(meta);
  }
  var belongsTo$1 = _exports.b = (0, _modelB638e17c.c)(belongsTo);
  function normalizeType(type) {
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/drive.inc24.net/applications/cms/node_modules/@ember-data/model").deprecations.DEPRECATE_NON_STRICT_TYPES)) {
      const result = (0, _emberInflector.singularize)((0, _string.dasherize)(type));
      (true && !(result === type) && (0, _debug.deprecate)(`The resource type '${type}' is not normalized. Update your application code to use '${result}' instead of '${type}'.`, result === type, {
        id: 'ember-data:deprecate-non-strict-types',
        until: '6.0',
        for: 'ember-data',
        since: {
          available: '5.3',
          enabled: '5.3'
        }
      }));
      return result;
    }
    return type;
  }

  /**
    `hasMany` is used to define One-To-Many and Many-To-Many
    relationships on a [Model](/ember-data/release/classes/Model).
  
    `hasMany` takes an optional hash as a second parameter, currently
    supported options are:
  
    - `async`: A boolean value used to explicitly declare this to be an async relationship. The default is true.
    - `inverse`: A string used to identify the inverse property on a related model.
    - `polymorphic` A boolean value to mark the relationship as polymorphic
  
    #### One-To-Many
    To declare a one-to-many relationship between two models, use
    `belongsTo` in combination with `hasMany`, like this:
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment') comments;
    }
    ```
  
    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class CommentModel extends Model {
      @belongsTo('post') post;
    }
    ```
  
    #### Many-To-Many
    To declare a many-to-many relationship between two models, use
    `hasMany`:
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('tag') tags;
    }
    ```
  
    ```app/models/tag.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class TagModel extends Model {
      @hasMany('post') posts;
    }
    ```
  
    You can avoid passing a string as the first parameter. In that case Ember Data
    will infer the type from the singularized key name.
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany tags;
    }
    ```
  
    will lookup for a Tag type.
  
    #### Explicit Inverses
  
    Ember Data will do its best to discover which relationships map to
    one another. In the one-to-many code above, for example, Ember Data
    can figure out that changing the `comments` relationship should update
    the `post` relationship on the inverse because post is the only
    relationship to that model.
  
    However, sometimes you may have multiple `belongsTo`/`hasMany` for the
    same type. You can specify which property on the related model is
    the inverse using `hasMany`'s `inverse` option:
  
    ```app/models/comment.js
    import Model, { belongsTo } from '@ember-data/model';
  
    export default class CommentModel extends Model {
      @belongsTo('post') onePost;
      @belongsTo('post') twoPost
      @belongsTo('post') redPost;
      @belongsTo('post') bluePost;
    }
    ```
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment', {
        inverse: 'redPost'
      })
      comments;
    }
    ```
  
    You can also specify an inverse on a `belongsTo`, which works how
    you'd expect.
  
    #### Sync relationships
  
    Ember Data resolves sync relationships with the related resources
    available in its local store, hence it is expected these resources
    to be loaded before or along-side the primary resource.
  
    ```app/models/post.js
    import Model, { hasMany } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @hasMany('comment', {
        async: false
      })
      comments;
    }
    ```
  
    In contrast to async relationship, accessing a sync relationship
    will always return a [ManyArray](/ember-data/release/classes/ManyArray) instance
    containing the existing local resources. But it will error on access
    when any of the known related resources have not been loaded.
  
    ```
    post.comments.forEach((comment) => {
  
    });
  
    ```
  
    If you are using `links` with sync relationships, you have to use
    `ref.reload` to fetch the resources.
  
    @method hasMany
    @public
    @static
    @for @ember-data/model
    @param {String} type (optional) type of the relationship
    @param {Object} options (optional) a hash of options
    @return {Ember.computed} relationship
  */
  function hasMany(type, options) {
    (true && !(options && typeof options.async === 'boolean') && (0, _debug.assert)(`Expected hasMany options.async to be a boolean`, options && typeof options.async === 'boolean')); // Metadata about relationships is stored on the meta of
    // the relationship. This is used for introspection and
    // serialization. Note that `key` is populated lazily
    // the first time the CP is called.
    let meta = {
      type: normalizeType(type),
      options,
      isRelationship: true,
      kind: 'hasMany',
      name: 'Has Many',
      key: null
    };
    return (0, _object.computed)({
      get(key) {
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/drive.inc24.net/applications/cms/node_modules/@ember-data/model").env.DEBUG)) {
          if (['currentState'].indexOf(key) !== -1) {
            throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your hasMany on ${this.constructor.toString()}`);
          }
        }
        if (this.isDestroying || this.isDestroyed) {
          return (0, _array.A)();
        }
        return (0, _modelB638e17c.l)(this).getHasMany(key);
      },
      set(key, records) {
        if ((0, _runtime.macroCondition)((0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/drive.inc24.net/applications/cms/node_modules/@ember-data/model").env.DEBUG)) {
          if (['currentState'].indexOf(key) !== -1) {
            throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your hasMany on ${this.constructor.toString()}`);
          }
        }
        const support = (0, _modelB638e17c.l)(this);
        const manyArray = support.getManyArray(key);
        (true && !(Array.isArray(records)) && (0, _debug.assert)(`You must pass an array of records to set a hasMany relationship`, Array.isArray(records)));
        this.store._join(() => {
          manyArray.splice(0, manyArray.length, ...records);
        });
        return support.getHasMany(key);
      }
    }).meta(meta);
  }
  var hasMany$1 = _exports.h = (0, _modelB638e17c.c)(hasMany);
});